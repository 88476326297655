// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #8e6d76;
	--ion-color-primary-rgb: 142, 109, 118;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #6b525a;
	--ion-color-primary-tint: #a78e94;
	--ion-color-primary-tint-rgb: 167, 142, 148;

	// основной цвет для кнопок в зависимости от светлой/темной темы
	--ion-color-main: var(--ion-color-primary);
	--ion-color-main-rgb: var(--ion-color-primary-rgb);
	--ion-color-main-contrast: var(--ion-color-primary-contrast);
	--ion-color-main-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-main-shade: var(--ion-color-primary-shade);
	--ion-color-main-tint: var(--ion-color-primary-tint);
	--ion-color-main-tint-rgb: var(--ion-color-primary-tint-rgb);
 
	--ion-color-secondary: #3a3e57;
	--ion-color-secondary-rgb: 39,41,61;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #34394f;
	--ion-color-secondary-tint: #4e5373;

	--ion-color-success: #71feaa;
	--ion-color-success-rgb: 129, 255, 179;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #1b713c;
	--ion-color-success-tint: #a6fcca;

	--ion-color-tertiary: #1d8cf8;
	--ion-color-tertiary-rgb: 29,140,248;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #1a7bda;
	--ion-color-tertiary-tint: #3498f9;

	--ion-color-warning: #ff8d72;
	--ion-color-warning-rgb: 255,141,114;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e07c64;
	--ion-color-warning-tint: #ff9880;

	--ion-color-danger: #f1a7a9;
	--ion-color-danger-rgb: 241, 167, 169;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #d02224;
	--ion-color-danger-tint: #f1a7a9;

  --ion-color-dark: #1e1e2f;
	--ion-color-dark-rgb: 30,30,47;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1a1a29;
	--ion-color-dark-tint: #353544;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f3efec;
	--ion-color-light-rgb: 243, 239, 236;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e7e3e1;
	--ion-color-light-tint: #f6f1ee;

	--ion-color-step-50: #0d0d0d;
	--ion-color-step-100: #1a1a1a;
	--ion-color-step-150: #262626;
	--ion-color-step-200: #333333;
	--ion-color-step-250: #404040;
	--ion-color-step-300: #4d4d4d;
	--ion-color-step-350: #595959;
	--ion-color-step-400: #666666;
	--ion-color-step-450: #737373;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #8c8c8c;
	--ion-color-step-600: #999999;
	--ion-color-step-650: #a6a6a6;
	--ion-color-step-700: #b3b3b3;
	--ion-color-step-750: #bfbfbf;
	--ion-color-step-800: #cccccc;
	--ion-color-step-850: #d9d9d9;
	--ion-color-step-900: #e6e6e6;
	--ion-color-step-950: #f2f2f2;

	--background: var(--ion-color-light);
  --ion-background-color: var(--ion-color-light);
	--pvz-border-radius: 24px;
	--ion-overlay-background-color: white;
	--ion-color-note: var(--ion-color-step-400);
	
	--ion-font-family: "Rubik", "Roboto", "Helvetica Neue", sans-serif;
}

/*
	* Dark Colors
	* -------------------------------------------
	*/

body.dark {

	--ion-color-danger: #fdd1d2;
	--ion-color-danger-rgb: 253, 209, 210;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #c95a61;
	--ion-color-danger-tint: #fbdcdd;

	--ion-color-success: #c5fbdb;
	--ion-color-success-rgb: 0,242,195;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #67c68c;
	--ion-color-success-tint: #d8fae6;

	// основной цвет для кнопок в зависимости от светлой/темной темы
	--ion-color-main: var(--ion-color-light);
	--ion-color-main-rgb: var(--ion-color-light-rgb);
	--ion-color-main-contrast: var(--ion-color-light-contrast);
	--ion-color-main-contrast-rgb: var(--ion-color-light-contrast-rgb);
	--ion-color-main-shade: var(--ion-color-light-shade);
	--ion-color-main-tint: var(--ion-color-light-tint);
	--ion-color-main-tint-rgb: var(--ion-color-light-tint-rgb);

	--background: linear-gradient(to top right, var(--ion-color-secondary-shade), var(--ion-color-secondary-tint) 50%);
	--ion-background-color: linear-gradient(to top right, var(--ion-color-secondary-shade), var(--ion-color-secondary-tint) 50%);
	--ion-background-color-rgb: var(--ion-color-dark-rgb);
	--ion-color-note: var(--ion-color-step-800);

	--ion-text-color: var(--ion-color-light);
	--ion-text-color-rgb: var(--ion-color-light-rgb);
}

.ion-color-main {
	--ion-color-base: var(--ion-color-main);
	--ion-color-base-rgb: var(--ion-color-main-rgb);
	--ion-color-contrast: var(--ion-color-main-contrast);
	--ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
	--ion-color-shade: var(--ion-color-main-shade);
	--ion-color-tint: var(--ion-color-main-tint);
}